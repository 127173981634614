import React, { Component } from 'react';
import './newsletter.module.scss';
import axios from 'axios';
import { validateForm } from '../../assets/js/custom-validation';
import logo from '../../assets/images/logo.png';

const { apiUrl } = process.env;
const { mailTo } = process.env;
const { mailCc } = process.env;
const { marketingWebUrl } = process.env;

/* eslint-disable-next-line */
export interface NewsletterProps {}

class Newsletter extends Component<NewsletterProps> {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      mailSent: false,
      error: null,
      isValidated: false,
    };
  }

  handleContactFormSubmit(event) {
    event.preventDefault();
    // Reset State
    this.setState({ error: null, mailSent: false });
    const { formEl } = this;
    const templateData = `
      <!doctype html>
      <html lang='en'>
      <head>
          <meta charset='UTF-8'><meta name='viewport' content='width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0'>
          <meta http-equiv="X-UA-Compatible" content="ie=edge">
          <title>New Project Enquiry</title>
          <style>
              .container {width: 100%; max-width: 1000px; margin: 0 auto; padding: 0 15px}
              table { width: 100%; }
              .enrollingTemplate th p {margin: 0; text-align: left; font-weight: normal}
              .enrollingTemplate h2 {margin: 50px 0 25px}
              .enrollingTemplate tbody p {margin: 0; line-height: 23px}
              .enrollingTemplate tbody p+p {margin: 15px 0 0;}
              .enrollingTemplate ul {padding-left: 15px}
              .enrollingTemplate ul li {margin: 10px 0 0}
              .enrollingTemplate ul li ul {padding-left: 25px; list-style: disc}
              .enrollingTemplate ul li ul li {margin: 5px 0 0}
              .d-flex {display: flex; margin: 50px 0 25px; width: 100%}
              .double-img img { margin: 15px; max-width: 40%; max-height: 150px }
              p { padding-bottom: 15px; }
              .vcolor{ color: #118440; }
          </style>
      </head>
      <body>
          <div class="container">
              <div class="enrollingTemplate">
                  <p>Dear DGM Team,<br><br>You are receiving this because someone has posted a New Newsletter Inquiry from the website. <a href="${marketingWebUrl}">${marketingWebUrl}</a></p>
                  <table border=1>
                      <tbody>
                          <tr><td><b>First Name*</b></td><td><span class="vcolor">[FirstName]</span></td></tr>
                          <tr><td><b>Last Name*</b></td><td><span class="vcolor">[LastName]</span></td></tr>
                          <tr><td><b>Email Address*</b></td><td><span class="vcolor">[Email]</span></td></tr>
                      </tbody>
                  </table>
                  <p>Regards, DGM Team<br><br><img src="${logo}" width="10%" alt="DGM"/></p>
              </div>
          </div>
      </body>
      </html>`;
    // If form validate
    if (validateForm(formEl)) {
      const html = templateData
        .replace('[FirstName]', this.state.firstName)
        .replace('[LastName]', this.state.lastName)
        .replace('[Email]', this.state.email);

      const data = {
        emailTo: mailTo,
        emailCc: mailCc,
        emailSubject: 'New Newsletter Inquiry - DGM Website',
        emailTemplate: html,
      };

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${apiUrl}/mailer/send`,
          headers: { 'content-type': 'application/json' },
          data,
        })
          .then((result) => {
            if (result.data.message == 'Email has been sent successfully.') {
              this.setState({
                firstName: '',
                lastName: '',
                email: '',
                mailSent: true,
                isValidated: false,
              });
              //window.location.reload();
            } else {
              this.setState({ error: result.data.error });
            }
          })
          .catch((error) => {
            this.setState({ error: error.message });
          });
      });
    }
    this.setState({ isValidated: true });
    return false;
  }

  render() {
    if (typeof window !== 'undefined') {
      const setActive = (element, active) => {
        const formField = element.parentNode.parentNode;
        if (active) {
          formField.classList.add('form-field--is-active');
        } else {
          formField.classList.remove('form-field--is-active');
          element.value === ''
            ? formField.classList.remove('form-field--is-filled')
            : formField.classList.add('form-field--is-filled');
        }
      };

      Array.prototype.forEach.call(
        document.querySelectorAll('.inputMod'),
        (element) => {
          element.addEventListener('blur', () => {
            setActive(element, false);
          });

          element.addEventListener('focus', () => {
            setActive(element, true);
          });
        }
      );
    }
    const classNames = [];
    if (this.state.isValidated) {
      classNames.push('was-validated');
    }

    return (
      <section id="newsletter">
        <div className="container">
          <div className="newsletter-container">
            <div className="row align-items-center">
              <div className="col-md-6 d-flex align-items-center border-right">
                <div className="newsletter-text text-right">
                  <h5 className="x-small-heading transf-caps color-white">
                    Newsletter
                  </h5>
                  <h3 className="medium-heading medium-wgt color-white">
                    For a free subscription to our newsletter
                  </h3>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <div className="newsletter-form">
                  <form
                    method="post"
                    action="#"
                    ref={(form) => {
                      this.formEl = form;
                    }}
                    className={classNames}
                    noValidate
                  >
                    {this.state.mailSent && (
                      <div className="alert-success alert text-center mb-5">
                        Thank you for contacting us.
                      </div>
                    )}
                    {this.state.error && (
                      <div className="alert-danger error alert text-center  mb-5">
                        {this.state.error}
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="text"
                          placeholder="First Name*"
                          className="form-control form-outline mar-bottom-rem"
                          required
                          value={this.state.firstName}
                          onChange={(e) => {
                            this.setState({ firstName: e.target.value });
                          }}
                        />
                        <div className="invalid-feedback" />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          placeholder="Last Name*"
                          className="form-control form-outline mar-bottom-rem"
                          required
                          value={this.state.lastName}
                          onChange={(e) => {
                            this.setState({ lastName: e.target.value });
                          }}
                        />
                        <div className="invalid-feedback" />
                      </div>
                      <div className="col-12">
                        <input
                          type="email"
                          placeholder="Email Address*"
                          className="form-control form-outline mar-bottom-rem"
                          required
                          value={this.state.email}
                          onChange={(e) => {
                            this.setState({ email: e.target.value });
                          }}
                        />
                        <div className="invalid-feedback" />
                      </div>
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn-theme-outline btn-sm font-primary radius-zero"
                          onClick={(e) => this.handleContactFormSubmit(e)}
                        >
                          Subscribe
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Newsletter;
