import './footer.module.scss';
import logo from '../../assets/images/logo.png';
import fb from '../../assets/images/facebook.svg';
import linkedin from '../../assets/images/linkedin.svg';
import inst from '../../assets/images/instagram.svg';
/* eslint-disable-next-line */
export interface FooterProps {}

export function Footer(props: FooterProps) {
  return (
    <footer>
      <div className="footer-info">
        <div className="container">
          <div className="footer-row">
            <div className="info-col">
              <div className="logo-f">
                <a href={'/'}>
                  <img src={logo} alt="footer logo" />
                </a>
              </div>
              <p>
                <a
                  className="color-white"
                  href="https://goo.gl/maps/fXoEtmNfp7tdBFe39"
                  target="_blank"
                >
                  101 Eisenhower Parkway <br /> Suite 300 <br /> Roseland, NJ
                  07068 <br />{' '}
                </a>

                <br />
                <a className="color-white" href="tel:800-484-0350">
                  800-484-0350
                </a>
              </p>
            </div>
            <div className="footer-nav-wrap">
              <div className="nav-col">
                <h5>About Us</h5>
                <ul>
                  <li>
                    <a href="/who-we-are">Who We Are</a>
                  </li>
                  <li>
                    <a href="/resources">Resources</a>
                  </li>
                  <li>
                    <a href="/contact-us">Careers</a>
                  </li>
                  <li>
                    <a href="/contact-us">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div className="nav-col">
                <h5>
                  <a
                    href="/our-services/services-for-Individuals"
                    className="color-white"
                  >
                    Services For Individuals
                  </a>
                </h5>
                <ul>
                  <li>
                    <a href="/our-services/services-for-Individuals/wealth-planning-services">
                      Wealth Planning
                    </a>
                  </li>
                  <li>
                    <a href="/our-services/services-for-Individuals/portfolio-management">
                      Portfolio Management
                    </a>
                  </li>
                  <li>
                    <a href="/our-services/services-for-Individuals/estate-planning">
                      Estate Planning
                    </a>
                  </li>
                  <li>
                    <a href="/our-services/services-for-Individuals/insurance-and-tax-strategies">
                      Insurance and Tax Strategies
                    </a>
                  </li>
                  <li>
                    <a href="/our-services/services-for-Individuals/socially-responsible-investing">
                      Socially Responsible Investing
                    </a>{' '}
                  </li>
                </ul>
              </div>
              <div className="nav-col">
                <h5>
                  <a
                    href="/our-services/services-for-businesses"
                    className="color-white"
                  >
                    Services For Businesses
                  </a>
                </h5>
                <ul>
                  <li>
                    <a href="/our-services/services-for-businesses/retirement-plan-services">
                      Retirement Plan Services
                    </a>
                  </li>
                  <li>
                    <a href="/our-services/services-for-businesses/business-succession-planning">
                      Business Succession Planning
                    </a>
                  </li>
                  <li>
                    <a href="/our-services/services-for-businesses/institutional-cash-management">
                      Institutional Cash Management
                    </a>
                  </li>
                  <li>
                    <a href="/our-services/services-for-businesses/bespoke-business-services">
                      Bespoke Business Services
                    </a>
                  </li>
                </ul>
              </div>
              <div className="nav-col">
                <h5>
                  <a href="/our-specialities" className="color-white">
                    Our Specialties
                  </a>{' '}
                </h5>
                <ul>
                  <li>
                    <a href="/our-specialities#business">Business Owners</a>
                  </li>
                  <li>
                    <a href="/our-specialities#disablities">
                      Loved Ones with Disabilities
                    </a>
                  </li>
                  <li>
                    <a href="/our-specialities#executives">Executives</a>
                  </li>
                  <li>
                    <a href="/our-specialities#medical">
                      Medical Professionals{' '}
                    </a>
                  </li>
                  <li>
                    <a href="/our-specialities#commercial">
                      Commercial Real Estate Owners
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copy">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="text-copy">
                &copy; 2021 DGM. All Rights Reserved.
              </div>
            </div>
            <div className="col-md-6">
              <div className="social">
                <ul>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/dgm-asset-management/"
                      target="_blank"
                    >
                      <img src={linkedin} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/dgmwealth/"
                      target="_blank"
                    >
                      <img src={inst} height="25" width="25" alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/DGM-Asset-Management-106660478350614"
                      target="_blank"
                    >
                      <img src={fb} alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
